<template>
  <div id="roster">
    <b-card>
      <div class="row">
        <div class="col-md-3">
          <h3 v-if="filterData.status == 0" class="title">Pending Rosters</h3>
          <h3 v-else class="title">Accepted Roster</h3>
        </div>
        <div class="col-md-9">
          <filter-component @filter="filter" />
        </div>
      </div>
      <uploaded-roster-component ref="uploaded-roster" :filterData="filterData" v-if="filterData.view_as && filterData.view_as.name === 'Employee List'" />
      <sister-company-roster-component ref="sister-company-roster" :filterData="filterData" v-else />
    </b-card>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import FilterComponent from "./components/FilterComponent.vue";
import SisterCompanyRosterComponent from './components/SisterCompanyRosterComponent.vue';
import UploadedRosterComponent from "./components/UploadedRosterComponent.vue";

export default {
  components: {
    BCard,
    UploadedRosterComponent,
    SisterCompanyRosterComponent,
    FilterComponent,
  },
  data() {
    return {
      filterData: {
        role: "admin",
        company: null,
        customer: null,
        sector: null,
        user_id: null,
        customer_id: null,
        sector_id: null,
        status: 0,
      },
    };
  },
  methods: {
    filter(query) {
      this.filterData = query;
      this.$refs['uploaded-roster']?.reCall();
      this.$refs['sister-company-roster']?.reCall();
    },
  },
};
</script>
<style scoped>
.title {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 43px;
  color: #515b60;
  margin-left: 20px;
}
.row {
  margin-right: 10px;
}
</style>
